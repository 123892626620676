export const questionnaire = [
  {
    question: "Please select your sex assigned at birth",
    answers: [
      {
        answer: "Female",
      },
      {
        answer: "Male",
      },
      {
        answer: "Other",
      },
    ],
    type: "radio",
    standardDemo: "sex",
  },
  {
    question: "Have you been diagnosed with or treated for any of the following conditions within the past year? Please select all that apply.",
    answers: [
      {
        answer:
          "Sexually transmitted infections (STIs)",
        showConditional: true,
      },
      {
        answer:
          "HIV/AIDS",
        showConditional: true,
      },
      {
        answer:
          "Liver disease",
        showConditional: true,
      },
      {
        answer:
          "Kidney disease",
        showConditional: true,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    isConditional: false,
    type: "checkBox",
  },
  {
    question: "Please tell us more about:",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
    showPreviousAnswers: true,
  },
  {
    question: "Have you experienced any of the following symptoms within the past 30 days? Please select all that apply.",
    answers: [
      {
        answer:
          "Unexplained genital sores or ulcers",
        showConditional: false,
      },
      {
        answer:
          "Painful urination",
        showConditional: false,
      },
      {
        answer:
          "Abnormal discharge from the genital area",
        showConditional: false,
      },
      {
        answer:
          "Lower abdominal pain",
        showConditional: false,
      },
      {
        answer:
          "Swollen lymph nodes",
        showConditional: false,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    isConditional: false,
    type: "checkBox",
  },
  {
    question: "Have you had any recent:",
    subText: "Please select all that apply.",
    answers: [
      {
        answer:
          "Condomless/unprotected oral, anal or vaginal sex",
        showConditional: true,
      },
      {
        answer:
          "Exposure to people with STIs/who recently got tested positive for STIs",
        showConditional: true,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    isConditional: false,
    type: "checkBox",
  },
  {
    question: "Please provide details about:",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
    showPreviousAnswers: true,
  },
  {
    question:
      "Please list any current medicines, vitamins or dietary supplements you take regularly:",
    subText: "Please include the dosage.",
    answers: ["selfReportedMeds"],
    type: "freeText",
    standardDemo: "selfReportedMeds",
    isConditional: false,
  },
  {
    question: "Please list all known allergies:",
    answers: ["allergies"],
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  {
    question: "Please list any prior medical conditions or serious problems:",
    answers: ["medicalConditions"],
    type: "freeText",
    standardDemo: "medicalConditions",
    isConditional: false,
  },
  {
    question:
      "Is there anything else you want your doctor to know about your condition or health?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Please enter anything else you want your doctor to know about your condition or health.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
];
