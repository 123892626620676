export const checkoutText = {
  header: `IT LOOKS LIKE AN ONLINE DOCTOR CONSULT IS RIGHT FOR YOU!`,
  body: `Your doctor consult will be provided by Beluga Health, a telemedicine provider that serves patients across the country. If medically appropriate, treatment may be prescribed to you to your pharmacy of choice. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.`,
  footer: `In order to verify your identity, please upload a picture of your government-issued photo ID and your test result(s).`,
};

export const introPage = {
  header: `The following questions will help our physicians determine how best to assist you with your STI care.`,
  body: `If we determine you are eligible, you'll pay a $30 consultation fee to Beluga Health for your doctor consult.`,
  footer: `Beluga Health is a licensed telemedicine provider.`,
  knowFooter: 'Need help? Get in touch at support@testwithknow.com'
};

export const paymentSuccess = {
  header: `Thank you! Your information was submitted successfully.`,
  body: `A doctor will now review your intake form. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.`,
  footer: `Beluga Health is a licensed telemedicine provider.`,
};

export const dataSecure = {
  header:
    "Your personal information is securely transmitted via HIPAA compliant transfer to the Beluga Health medical team.",
};
