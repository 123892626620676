import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { checkoutText } from '../DataFiles/standardText';

import '../MainForm/MainForm.css';
import './PhotoIdForm.css';
import { convertHeicToJpeg, isHeicImageFile } from '../utils/heicFile';
import IconCloseCircle from './CloseIcon';

const MAX_ALLOWED = 5;

const PhotoIdForm = (props) => {
  const { photos, setPhotos } = props;
  const [previewImage, setPreviewImage] = useState(null);

  const [error, setError] = useState('');

  const convertAndReadHeic = async (file, reader) => {
    try {
      const convertedFile = await convertHeicToJpeg(file);
      reader.readAsDataURL(convertedFile);
    } catch (error) {
      console.error('Failed converting file', error);
      setError(
        'There is an issue with this file please try a different format.'
      );
    }
  };

  const handlePhoto = async (e) => {
    setError('');
    const originalEvent = e;
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      if (isHeicImageFile(file)) {
        await convertAndReadHeic(file, reader);
      } else {
        reader.readAsDataURL(file);
      }

      reader.onload = (e) => {
        const imgElement = document.createElement('img');
        imgElement.src = e.target.result;
        imgElement.onload = (imgE) => {
          const canvas = document.createElement('canvas');
          const maxWidth = 1000 > imgE.target.width ? imgE.target.width : 1000;
          const scaleSize = maxWidth / imgE.target.width;
          canvas.width = maxWidth;
          canvas.height = imgE.target.height * scaleSize;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(imgE.target, 0, 0, canvas.width, canvas.height);

          const encoded = ctx.canvas.toDataURL('image/jpeg');
          const stripped = encoded.replace('data:image/jpeg;base64,', '');

          setPhotos((prev) => {
            let alreadyPicked = false;
            if (prev?.length > 0) {
              alreadyPicked = prev.some((p) => {
                return p.fullSrc === encoded
              })
            }
            if (alreadyPicked) {
              return prev;
            }
            const updated = [
              ...prev,
              {
                filename: file.name,
                photo: stripped,
                fullSrc: encoded,
              },
            ];

            return updated;
          });

          // reset the input
          originalEvent.target.value = null;

        };
      };
    } else {
      return;
    }
  };

  const removePhoto = (index) => {
    setPhotos((prev) => {
      const left = prev.slice(0, index);
      const rest = prev.slice(index + 1, prev.length);
      return [...left, ...rest];
    })
  };

  return (
    <div className="photoIdContainer">
      <div className="checkoutFooter">{checkoutText.footer}</div>
      <div className="uploadRow">
        {photos?.length >= MAX_ALLOWED ? (
          <div>
            Max upload is 5 documents, please remove one if you want to make an
            update.
          </div>
        ) : (
          <label htmlFor="fileUpload" className="orderButton fileUpload">
            Choose Photo
          </label>
        )}

        <input
          disabled={photos?.length >= MAX_ALLOWED}
          type="file"
          accept="image/*"
          id="fileUpload"
          name="photo"
          onChange={handlePhoto}
          className="displayNone"
        />
      </div>

      <div className="uploadedPhotoDisplay row">
        {photos?.map((photo, index) => {
          return (
            <div className="col" key={photo.filename}>
              <div  className="photoContainer d-flex justify-content-center">
                <div className="p-0 photoImageContainer">
                  <img
                    onClick={() => setPreviewImage(photo)}
                    key={photo.filename}
                    className="uploadedImg"
                    alt={photo.filename}
                    src={photo.fullSrc}
                  />
                  <div
                    className="ml-1 closeButton"
                    onClick={() => {
                      removePhoto(index);
                    }}
                  >
                    <IconCloseCircle />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {error && <div className="checkoutError">{error}</div>}

      <Modal
        className='imagePreviewModal'
        size="lg"
        show={!!previewImage}
        onHide={() => {
          setPreviewImage(null);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {previewImage && (
            <img
              key={previewImage.filename}
              className="previewImg"
              alt={previewImage.filename}
              src={previewImage.fullSrc}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PhotoIdForm;
